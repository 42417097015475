import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import { getnfttokenreport } from './../../actions/users';
import Search from "@material-ui/icons/Search";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import customInputStyle from "assets/jss/material-dashboard-react/components/customInputStyle.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { Link, useHistory } from "react-router-dom";
import EditIcon from '@material-ui/icons/Edit';
import { acceptverification, rejectverification,BurnField} from '../../actions/users';
import config from '../../lib/config';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Web3 from "web3";
import single from '../../ABI/singleABI';
import multiple from '../../ABI/multipleABI';
import singlecollection from '../../ABI/singlecollectionABI';
import multiplecollection from '../../ABI/multiplecollectionABI';
import { toast } from 'react-toastify';

toast.configure();
let toasterOption = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
}
const rows = [
    // getData('')
    //   createData('firstName', 'lastName', 'Email', 'phoneNo', 'accountType')
    //   createData('Donut', 452, 25.0, 51, 4.9),
];


const headCells = [
    { id: 'User Address', numeric: false, disablePadding: true, label: ' User Address' },
    { id: 'tokencounts', numeric: true, disablePadding: false, label: 'Nft Token' },
    { id: 'description', numeric: true, disablePadding: false, label: 'description' },
];

function EnhancedTableHead(props) {
    
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'center' : 'center'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 76%',
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onChangeterm: PropTypes.func.isRequired
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));


const customStyle = makeStyles(customInputStyle);

export default function EnhancedTable() {
    const classes = useStyles();
    const customStyles = customStyle();
    var [accverificationforcheckbox, setckeckboxvalueforaccver] = useState();
    const classesSearch = useToolbarStyles();
    const [data, setData] = React.useState(0);
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [userDetails, setUser] = useState();
    const [searchQuery, setSearchQuery] = React.useState("");
    const [typingTimeout, setTypingTimeout] = useState(0);
    const [open, setopen] = useState()
    const [close, setclose] = useState()
    var [selectedduerid,setselecteduserid]=useState()
    const [show12, setShow12] = useState(false);
    const handleClose12 = () => setShow12(false);
    const handleShow12 = () => setShow12(true);
    const [BurnLoading,setBurnLoading]=useState("try");
   
    const [opendl, setOpen] = React.useState(false);
  
    const handleClickToOpen = (e) => {
        setselecteduserid(e)
      setOpen(true);
    };
    
    const handleToClose = () => {
      setOpen(false);
    }
    function editR(id) 
    {
        if(id!='') {
            window.location="/useredit/"+id;
        }
        
    }
    function lock(){

    }
    async function accept(idd){
        console.log("==========================inside acccept")
      let reqData = {
        "id":idd
      }
      console.log(reqData);
      let result = await acceptverification(reqData);
      getAll()
    }

    async function reject(idd){
      let reqData = {
        "id":idd
      }
      console.log(reqData);
      let result = await rejectverification(reqData);
      getAll()
    }
    const handleChange = (event) => {
        const { name, value } = event.target;
        const self = this;
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }

        setSearchQuery(value)
        setTypingTimeout(setTimeout(function () {
            getAll({ 'search': value });
        }, 1000))
    }

    const handleRequestSort = (event, property) => {
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };
    useEffect(() => {
        getAll();
    }, [])

    const getAll = async (search = {}) => {
        var res = await getnfttokenreport();
        console.log(res.result.result,"===res.result.result")
        if(res && res.result && res.result.result){
            setUser(res.result.result);
        }else{
            setUser([]);
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    async function FormSubmit(item){  
      var networkVersion = config.network
      setBurnLoading('processing');  
      if (window.ethereum) { 
      var web3 = new Web3(window.ethereum);  
      try {  
        if (typeof web3 !== 'undefined') { 
        window.ethereum.enable().then(async function() { 
        const web3  = new Web3(window.web3.currentProvider)  
        if (window.web3.currentProvider.isMetaMask === true) { 
          await web3.eth.getAccounts(async function(error, result) { 
           if(window.web3.currentProvider.networkVersion == networkVersion){ 
               var logaddress =result[0];  
               const singleContract = new web3.eth.Contract(single, item.tokendetails.contractAddress); 
               const multipleContract = new web3.eth.Contract(multiple, item.tokendetails.contractAddress);  
               const singlecollectionContract = new web3.eth.Contract(singlecollection, item.tokendetails.contractAddress);
               const multiplecollectionContract = new web3.eth.Contract(multiplecollection, item.tokendetails.contractAddress);
               var CoursetroContract = item.tokendetails.type == 721 && item.tokendetails.contracttype == "normal"? singleContract :item.tokendetails.contracttype == "normal" && item.tokendetails.type == 1155 ? multipleContract 
                                       :item.tokendetails.type == 721 && item.tokendetails.contracttype == "collection"? singlecollectionContract : multiplecollectionContract
               var owner = await CoursetroContract.methods.owner().call(); 
               if(owner==logaddress){  
               if(item.tokendetails.type == 721){ 
                CoursetroContract.methods  
                  .burn( 
                      item.tokendetails.tokenCounts,  
                  )  
                  .send({ from: logaddress })  
                  .then(async (result) => {  
                      setBurnLoading('done');  
                      var postData = { 
                        tokenOwner: item.tokendetails.tokenOwner, 
                        tokenCounts: item.tokendetails.tokenCounts, 
                        blockHash: result.blockHash, 
                        transactionHash: result.transactionHash, 
                        contractAddress:item.tokendetails.contractAddress,  
                        type:item.tokendetails.type,  
                        balance:item.tokendetails.balance,  
                        currAddr:item.tokendetails.tokenOwner,  
                        quant:item.tokendetails.balance 
                      }  
                      var updateBurnField = await BurnField(postData)  
                      if(updateBurnField){ 
                      toast.success('Burned successfully',toasterOption) 
                      window.location.reload();  
                      }  
                  }) 
                  .catch((error) => {  
                              setBurnLoading('try'); 
                              console.log('error : ', error);  
                              toast.error('Burn not placed', toasterOption)  
                          }) 
               }else{  
                 CoursetroContract.methods 
                  .burn( 
                      item.tokendetails.tokenOwner,  
                      item.tokendetails.tokenCounts,  
                      item.tokendetails.balance 
                   ) 
                  .send({ from: logaddress })  
                  .then(async (result) => {  
                      setBurnLoading('done');  
                      console.log('result : ', result);  
                      var postData = { 
                        tokenOwner: item.tokendetails.tokenOwner, 
                        tokenCounts: item.tokendetails.tokenCounts, 
                        blockHash: result.blockHash, 
                        transactionHash: result.transactionHash, 
                        contractAddress:item.tokendetails.contractAddress,  
                        type:item.tokendetails.type,  
                        balance:item.tokendetails.balance,  
                        currAddr:item.tokendetails.tokenOwner,  
                        quant:item.tokendetails.balance 
                      }  
                      var updateBurnField = await BurnField(postData)  
                      if(updateBurnField){ 
                        toast.success('Burned successfully',toasterOption) 
                        document.getElementById('closeburn').click() 
                        window.location.reload();  
                      }  
                  }) 
                  .catch((error) => {  
                    setBurnLoading('try'); 
                    toast.error('Burn not placed', toasterOption)  
                  }) 
                  }  
                  }else{ 
                    setBurnLoading('try'); 
                    toast.error('Please Choose Admin Address to proceed ', toasterOption)  
                  }  
                 }else{  
                  setBurnLoading('try'); 
                   toast.error('Please Choose Mainnet', toasterOption) 
                 } 
                }) 
            }else{ 
              setBurnLoading('try'); 
               toast.error('Please Add Metamask External', toasterOption)  
            }  
          }) 
          }else{ 
              setBurnLoading('try'); 
               toast.error('Please Add Metamask External', toasterOption)  
            }  
        }catch(err){ 
          setBurnLoading('try'); 
          toast.error('Failed Try Again Later', toasterOption) 
        }  
      }else{ 
        setBurnLoading('try'); 
        toast.error('Please Add Metamask External', toasterOption) 
      }  
    } 

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Toolbar
                    className={clsx(classesSearch.root, {
                        [classesSearch.highlight]: selected.length > 0,
                    })}
                >
                    <Typography className={classesSearch.title} variant="h6" id="tableTitle" component="div">
                        Report Details
                    </Typography>
                    <div className={classesSearch.searchWrapper}>
                        <FormControl
                            className={classesSearch.margin + " " + classesSearch.search + " " + customStyles.formControl}
                        >
                            
                        </FormControl>
                    </div>
                </Toolbar>
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        // size={dense ? 'small' : 'medium'}
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            //   order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {userDetails && userDetails .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                // const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow>
                                        <TableCell align="center" component="th"
                                            scope="row" padding="none">
                                            {row.useraddress}
                                        </TableCell>
                                        <TableCell align="center">{row.tokendetails && row.tokendetails.tokenName}</TableCell>
                                        <TableCell align="center">{row.description}</TableCell>
                                    </TableRow>
                                );
                            })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={userDetails && userDetails.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}