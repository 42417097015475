import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PhoneInput from 'react-phone-input-2'

import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { toastAlert } from '../../lib/toastAlert'

//import avatar from "assets/img/faces/marc.jpg";
import isEmpty from '../../lib/isEmpty';

import { getDapps, updateDAPPcategory, getdappcatnameaxios } from '../../actions/users';

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

// toaster config
toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
}


const initialFormValue = {
  userId: "",
  category: "",
  title: "",
  description: "",
  appimage: "",
  url: ""
}

const useStyles = makeStyles(styles);

export default function Categoryedit(props) {
  const classes = useStyles();
  const history = useHistory();
  const [toched, setToched] = useState({});

  const dispatch = useDispatch();
  const [userdet, setUser] = useState();
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});
  const [caterr, setcaterr] = useState();
  const { userId } = useParams();
  const [selectedcatid, setseletedcatid] = useState();
  const [catname, setcatname] = useState([]);
  const [editcatid, setecitcatid] = useState();

  const onChange = (e) => {
    setcaterr("")
    e.preventDefault();
    const { id, value } = e.target;
    let formData = { ...formValue, ...{ [id]: value } }
    setFormValue(formData)
    console.log(formValue);
  }

  const {
    category,
    title,
    description,
    appimage,
    url
  } = formValue

  useEffect(() => {

    getdappcatname();

  }, [])

  const getdappcatname = async (search = {}) => {

    let filterData = search;
    if (filterData.search == '') {
      filterData = {}
    }
    var res = await getdappcatnameaxios(filterData);
    setcatname(res.result.data)
    console.log('result=================dddddddddddddddddddddddddddddddddddd==', res.result.data);
    if (res && res.result) {
      console.log('result===================', res);
      setUser(res.result.data);
      console.log('result of')
    }

  }


  const handlePhoneNumber = (value, country) => {
    const { dialCode } = country;
    let phoneNo = value;
    let formData = formValue;
    if (dialCode) {
      formData = {
        ...formData, ...{
          phoneCode: dialCode,
          phoneNo: phoneNo.slice(dialCode.length),
        }
      }
    } else if (value) {
      formData = { ...formData, ...{ phoneNo } }
    }
    setFormValue(formData)
  }

  const handleBlurPhone = (e) => {
    setToched({ ...toched, ...{ 'phoneNo': true, 'phoneCode': true } })
  }

  const handleFile = (event) => {
    event.preventDefault();
    //alert("sasasasa");
    // console.log(event.target.files[0])
    //settmpupimagefront(URL.createObjectURL(event.target.files[0]));
    const { id, files } = event.target;
    let formData = { ...formValue, ...{ [id]: files[0] } }
    setFormValue(formData)
  };

  const handleFormSubmit = async (e) => {
    try {
      //console.log("saran");
      e.preventDefault();
      if ((selectedcatid == '') || (selectedcatid == null) || (title == '') || (title == null) || (description == '') || (description == null) || (appimage == '') || (appimage == null) || (url == '') || (url == null)) {
        toastAlert('error', "Enter ALL Field!!!")

      } else {

        let reqData = new FormData();

        reqData.append("selectedcatid", selectedcatid)
        reqData.append("userId", userId)
        reqData.append("title", title)
        reqData.append("description", description)
        reqData.append("url", url)
        reqData.append("appimage", appimage)

        console.log(selectedcatid, 'selectedcatid');
        console.log(userId, 'userrrrrrrrrrrrrr')
        console.log(reqData, 'reqDatareqData')

        const { status, loading, error, message, result } = await updateDAPPcategory(reqData);
        if (status == 'success') {
          console.log(message, 'messssssss')
          toastAlert('success', message)
          history.push("/DAPPSlist")
          //window.location = "/defiwallet/DAPPSlist";
        } else {
          toastAlert('error', message, 'category')
        }

      }
    } catch (err) {
      console.log(err, 'errrrrrrrrrrrrrrr')
    }
  }



  let formdata = {};
  const getcategorys = async () => {
    var data = {
      id: userId
    }
    // console.log(data, 'data')
    const { status, loading, error, result } = await getDapps(data);
    console.log(result, 'testttttttttttt')
    if (status == 'success') {
      // formdata['category'] = test.result.data.categoryId;
      let data = {
        'category': result.categoryId,
        'title': result.title,
        'description': result.description,
        'url': result.url,
        'appimage': result.appimage,
      }
      setFormValue(data)
      // setFormValue(formdata)
    }

  }
  const selecedcatis = (e) => {
    setseletedcatid(e.target.value)
  }

  useEffect(() => {
    getcategorys()
  }, [])


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form className={classes.form} noValidate onSubmit={handleFormSubmit}>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Update DAPPS</h4>
                {/* <p className={classes.cardCategoryWhite}>Create a new user</p> */}
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <select name="category" id="category" onChange={selecedcatis}>
                      <option value="1" >Select Catogry</option>
                      {catname.length > 0 &&
                        catname.map((item, i) => {
                          console.log(item, 'iiiiiiiiiiiii')
                          return (

                            <option value={item._id}>{item.categoryName}</option>
                          );
                        })}
                    </select>

                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Title"
                      onChange={onChange}
                      id="title"
                      value={title}

                      formControlProps={{
                        fullWidth: true
                      }}
                    />

                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="description"
                      onChange={onChange}
                      id="description"
                      value={description}

                      formControlProps={{
                        fullWidth: true
                      }}
                    />

                  </GridItem>

                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="URL"
                      onChange={onChange}
                      id="url"
                      value={url}

                      formControlProps={{
                        fullWidth: true
                      }}
                    />

                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <input
                      // labelText="Profile image"
                      onChange={handleFile}
                      id="Appimage"
                      type="file"
                    // formControlProps={{
                    //   fullWidth: true
                    // }}
                    />
                    {
                      validateError.Appimage && <span className={classes.textDanger}>{validateError.Appimage}</span>
                    }

                    {/* <CustomInput
                      labelText="App Image"
                      onChange={onChange}
                      id="appimage"
                      value={appimage}

                      formControlProps={{
                        fullWidth: true
                      }}
                    /> */}
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color="primary" type="submit">Update</Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
