let key = {};
// if (process.env.NODE_ENV === "production") {
if (process.env.REACT_APP_MODE === "production") {
  console.log("Set Production Config");
  const API_URL = "https://api.dfxwallet.com";
  let blockurl = "https://testnet.bscscan.com/";
  key = {
    // baseUrl: `${API_URL}:2053`,
    baseUrl: `${API_URL}`,
    Front_URL: "https://controls.dfxwallet.com",
    blockurl: blockurl,
    network: 97,
  };
} else {
  console.log("Set Development Config");
  const API_URL = "https://api.dfxwallet.com";
  //const API_URL = 'http://localhost:2053';
  let blockurl = "https://testnet.bscscan.com/";
  key = {
    baseUrl: `${API_URL}`,
    Front_URL: "https://controls.dfxwallet.com",
    blockurl: blockurl,
    network: 97,
  };
}

export default key;
