import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

// import material ui
import { Select } from '@material-ui/core';


// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { useHistory, useParams } from "react-router-dom";
import { MenuItem, InputLabel } from '@material-ui/core';


// import action
import { updateCurrency, getSingleCurrency } from '../../actions/currency'

// import lib
import fileObjectUrl from '../../lib/fileObjectUrl';
import isEmpty from '../../lib/isEmpty';
import { toastAlert } from '../../lib/toastAlert';

const styles = theme => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
});

const initialFormValue = {
  'type': 'Crypto',
  'currencyName': '',
  'currencySymbol': '',
  "tokenType": '',
  'decimals': '',
  'withdrawFee': '',
  'minabi': '',   // if token
  'contractAddress': '',  // if token
  'bankName': '',  // if fiat
  'name': '',  // if fiat
  'accountNo': '', // if fiat
  'routingNo': '', // if fiat
  'currencyImage': "",
  'currencyId': ''
}
const useStyles = makeStyles(styles);

const EditCurrency = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();

  // state
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});

  const {
    type,
    currencyName,
    currencySymbol,
    tokenType,
    withdrawFee,
    decimals,
    minabi,
    contractAddress,
    bankName,
    currencyId,
    name,
    accountNo,
    routingNo,
    currencyImage,
  } = formValue


  // function

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } }
    setFormValue(formData)
  }

  const handleFile = (event) => {
    const { name, files } = event.target;
    let formData = { ...formValue, ...{ [name]: files[0] } }
    setFormValue(formData)
  };

  // console.log(params.currencyId, 'paramsparamsparams')



  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {

      if (currencyImage) {
        if (currencyImage.size > 20000) {
          setValidateError({ errors: { currencyImage: "Image size should be less than  20 Kb" } })
          toastAlert('error', "Image size should be less than  20 Kb", 'currencyUpdateModal')
          return false
        }
      }
      let reqData = new FormData();
      let data = params.currencyId
      reqData.append('currencyId', data);
      reqData.append('type', type);
      reqData.append('currencyName', currencyName);
      reqData.append('currencySymbol', currencySymbol);
      reqData.append('decimals', decimals);
      reqData.append('contractAddress', contractAddress);
      reqData.append('tokenType', tokenType);
      reqData.append('currencyImage', currencyImage);

      console.log(reqData, 'reqdataaaaaaa')
      const { status, loading, messages, error } = await updateCurrency(reqData);
      if (status == 'success') {
        // console.log(error)
        toastAlert('success', messages, 'addCurrency')
        history.push('/currency')
      } else {
        setValidateError(error);
      }
    }
    catch (err) {
      console.log(err, 'error')
    }
  }



  const fetchSingleCurrency = async () => {
    try {
      // console.log(params.currencyId, 'currencyId')
      const { status, loading, error, result } = await getSingleCurrency(params.currencyId);
      if (status == 'success') {
        console.log(result, 'result')
        let data = {
          'type': result.type,
          'currencyName': result.currencyName,
          'currencySymbol': result.currencySymbol,
          // 'withdrawFee': result.withdrawFee,
          'tokenType': result.tokenType,
          'decimals': result.decimals,
          // 'minabi': result.minABI,
          'contractAddress': result.contractAddress,
          // 'bankName': result.bankDetails.bankName,
          //'name': result.bankDetails.name,
          //'accountNo': result.bankDetails.accountNo,
          //'routingNo': result.bankDetails.routingNo,
          'currencyImage': result.currencyimage,
        }
        setFormValue(data)
      } else { }
    } catch (err) {
      console.log(err, 'error')
    }
  }

  useEffect(() => {
    fetchSingleCurrency();
  }, [])

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form method="POST" className={classes.form} noValidate onSubmit={handleFormSubmit}>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Update Currency</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <div className="custom_select">
                      <InputLabel id="demo-controlled-open-select-label">Currency Type</InputLabel>
                      <Select
                        className="d-block"
                        labelText="Currency Symbol"
                        name="type"
                        value={type}
                        onChange={handleChange}
                      >
                        <MenuItem value={"Fiat"}>Fiat</MenuItem>
                        <MenuItem value={"Crypto"}>Crypto</MenuItem>
                        <MenuItem value={"Token"}>Token</MenuItem>
                      </Select>
                    </div>

                    {
                      validateError.type && <span className={classes.textDanger}>{validateError.type}</span>
                    }

                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Currency Name"
                      name="currencyName"
                      value={currencyName}
                      onChange={handleChange}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                    {
                      validateError.currencyName && <span className={classes.textDanger}>{validateError.currencyName}</span>
                    }
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Currency Symbol"
                      name="currencySymbol"
                      value={currencySymbol}
                      onChange={handleChange}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                    {
                      validateError.currencySymbol && <span className={classes.textDanger}>{validateError.currencySymbol}</span>
                    }
                  </GridItem>

                  {/* <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Withdraw Fee"
                      name="withdrawFee"
                      value={withdrawFee}
                      onChange={handleChange}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                    {
                      validateError.withdrawFee && <span className={classes.textDanger}>{validateError.withdrawFee}</span>
                    }
                  </GridItem> */}

                  <GridItem xs={12} sm={12} md={4}>
                    {/* <div className="custom_select">
                      <InputLabel id="demo-controlled-open-select-label">Token Type</InputLabel>
                      <Select
                        className="d-block"
                        name="tokenType"
                        value={tokenType}
                        onChange={handleChange}
                      >
                        <MenuItem value={"ERC20"}>ERC20</MenuItem>
                        <MenuItem value={"TRC20"}>TRC20</MenuItem>
                        <MenuItem value={"BEP20"}>BEP20</MenuItem>
                      </Select> */}
                    <CustomInput
                      labelText="Token Type"
                      name="tokenType"
                      value={tokenType}
                      onChange={handleChange}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                    {
                      validateError.tokenType && <span className={classes.textDanger}>{validateError.tokenType}</span>
                    }
                    {/* </div> */}
                  </GridItem>
                  {/* {
                    type == 'token' && (
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="Min ABI"
                          name="minabi"
                          value={minabi}
                          onChange={handleChange}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                        {
                          validateError.minabi && <span className={classes.textDanger}>{validateError.minabi}</span>
                        }
                      </GridItem>
                    )
                  } */}

                  {
                    type == 'Token' && (
                      <GridItem xs={12} sm={12} md={4} >
                        <CustomInput
                          labelText="Contract Address"
                          name="contractAddress"
                          value={contractAddress}
                          onChange={handleChange}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                        {
                          validateError.contractAddress && <span className={classes.textDanger}>{validateError.contractAddress}</span>
                        }
                      </GridItem>

                    )
                  }

                  {type == 'Token' && (
                    <GridItem xs={12} sm={12} md={4} >
                      <CustomInput
                        labelText="Decimal"
                        name="decimals"
                        value={decimals}
                        onChange={handleChange}
                        formControlProps={{
                          fullWidth: true
                        }}
                      />
                      {
                        validateError.decimals && <span className={classes.textDanger}>{validateError.decimals}</span>
                      }
                    </GridItem>

                  )}


                  {/* {
                    type == 'Fiat' && (
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="Bank Name"
                          name="bankName"
                          value={bankName}
                          onChange={handleChange}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                        {
                          validateError.bankName && <span className={classes.textDanger}>{validateError.bankName}</span>
                        }
                      </GridItem>
                    )
                  } */}
{/* 
                  {
                    type == 'Fiat' && (
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="Name"
                          name="name"
                          value={name}
                          onChange={handleChange}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                        {
                          validateError.name && <span className={classes.textDanger}>{validateError.name}</span>
                        }
                      </GridItem>
                    )
                  } */}

                  {/* {
                    type == 'Fiat' && (
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="Account Number"
                          name="accountNo"
                          value={accountNo}
                          onChange={handleChange}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                        {
                          validateError.accountNo && <span className={classes.textDanger}>{validateError.accountNo}</span>
                        }
                      </GridItem>
                    )
                  } */}

                  {/* {
                    type == 'Fiat' && (
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="Routing Number"
                          name="routingNo"
                          value={routingNo}
                          onChange={handleChange}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                        {
                          validateError.routingNo && <span className={classes.textDanger}>{validateError.routingNo}</span>
                        }
                      </GridItem>
                    )
                  } */}

                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Currency Image"
                      onChange={handleFile}
                      name="currencyImage"
                      type="file"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                    <img src={fileObjectUrl(currencyImage)} width="50" height="50" />
                    {
                          validateError.currencyImage && <span className={classes.textDanger}>{validateError.currencyImage}</span>
                        }
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button
                  color="primary"
                  type="submit"
                >
                  Update
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default EditCurrency;