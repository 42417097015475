import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PhoneInput from 'react-phone-input-2'

import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { toastAlert } from '../../lib/toastAlert'
import { getdappcatnameaxios } from './../../actions/users';
//import avatar from "assets/img/faces/marc.jpg";
import isEmpty from '../../lib/isEmpty';

import { getcategory, addcategory, addDappcat } from '../../actions/users';

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

// toaster config
toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
}


const initialFormValue = {
  'Title': "",
  'Description': "",
  'AppImage': "",
  'Userurl': "",



}

const useStyles = makeStyles(styles);

export default function Categoryadd(props) {
  const classes = useStyles();
  const history = useHistory();
  const [toched, setToched] = useState({});
  const [caterr, setcaterr] = useState();
  const dispatch = useDispatch();
  const [userdet, setUser] = useState();
  const [selectedcatid, setseletedcatid] = useState();
  const [catname, setcatname] = useState([]);
  const [selectedImage, setSelectedImage] = useState();
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});

  const handleFile = (event) => {
    event.preventDefault();
    //alert("sasasasa");
    // console.log(event.target.files[0])
    //settmpupimagefront(URL.createObjectURL(event.target.files[0]));
    const { id, files } = event.target;
    let formData = { ...formValue, ...{ [id]: files[0] } }
    setFormValue(formData)
  };

  const { userId } = useParams();
  const onChange = (e) => {
    setcaterr("")
    e.preventDefault();
    const { id, value } = e.target;
    let formData = { ...formValue, ...{ [id]: value } }
    setFormValue(formData)
    // console.log(formValue);
  }

  const {
    category,
    Title,
    Description,
    AppImage,
    Userurl
  } = formValue

  useEffect(() => {
    getdappcatname();
  }, [])

  const getdappcatname = async (search = {}) => {
    let filterData = search;
    if (filterData.search == '') {
      filterData = {}
    }

    var res = await getdappcatnameaxios(filterData);
    setcatname(res.result.data)
    console.log('result=================dddddddddddddddddddddddddddddddddddd==', res.result.data);
    if (res && res.result) {
      console.log('result===================', res);
      setUser(res.result.data);
      // console.log('result of')
    }

  }

  const handlePhoneNumber = (value, country) => {
    const { dialCode } = country;
    let phoneNo = value;
    let formData = formValue;
    if (dialCode) {
      formData = {
        ...formData, ...{
          phoneCode: dialCode,
          phoneNo: phoneNo.slice(dialCode.length),
        }
      }
    } else if (value) {
      formData = { ...formData, ...{ phoneNo } }
    }
    setFormValue(formData)
  }
  const handleBlurPhone = (e) => {
    setToched({ ...toched, ...{ 'phoneNo': true, 'phoneCode': true } })
  }

  const selecedcatis = (e) => {

    setseletedcatid(e.target.value)

  }
  const handleFormSubmit = async (e) => {
    //console.log("saran");
    e.preventDefault();
    // console.log(AppImage, "AppImageAppImage");
    // return

    if ((selectedcatid == '') || (selectedcatid == null) || (Title == '') || (Title == null) || (Description == '') || (Description == null) || (AppImage == '') || (AppImage == null) || (Userurl == '') || (Userurl == null)) {
      toastAlert('error', "Enter ALL Field!!!")

      setcaterr("err")
    } else {

      let reqData = {
        selectedcatid,
        Title,
        Description,
        AppImage,
        Userurl
      }
      // console.log(AppImage);
      let bodyFormData = new FormData();
      bodyFormData.append("selectedcatid", selectedcatid)
      bodyFormData.append("Title", Title)
      bodyFormData.append("Description", Description)
      bodyFormData.append("Userurl", Userurl)
      bodyFormData.append("AppImage", AppImage)
      // console.log("yyyyyyyyyyyyyyyyyyy", AppImage);
      const { status, loading, message, error } = await addDappcat(bodyFormData);
      if (status == 'success') {
        toastAlert('success', message, 'category')
        history.push('/DAPPSlist')
        //  window.location="/defiwallet/DAPPSlist";
      } else {
      }
    }

  }

  let formdata = {};

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form className={classes.form} noValidate onSubmit={handleFormSubmit}>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>New DAPPS</h4>
                {/* <p className={classes.cardCategoryWhite}>Create a new user</p> */}
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <select className="d-block" name="category" id="category" onChange={selecedcatis}>
                      <option value="1" >Select Catogry</option>
                      {catname.length > 0 &&
                        catname.map((item, i) => {
                          return (

                            <option value={item._id}>{item.categoryName}</option>
                          );
                        })}
                    </select>

                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Title"
                      onChange={onChange}
                      id="Title"
                      value={Title}

                      formControlProps={{
                        fullWidth: true
                      }}
                    />

                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Description"
                      onChange={onChange}
                      id="Description"
                      value={Description}

                      formControlProps={{
                        fullWidth: true
                      }}
                    />

                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <input
                      // labelText="Profile image"
                      onChange={handleFile}
                      id="AppImage"
                      type="file"
                    // formControlProps={{
                    //   fullWidth: true
                    // }}
                    />
                    
                    {
                      validateError.AppImage && <span className={classes.textDanger}>{validateError.AppImage}</span>
                    }

                    {/* <CustomInput
                     labelText="App Image"
                      onChange={onChange}
                      id="AppImage"
                      value={AppImage}
                      
                      formControlProps={{
                        fullWidth: true
                      }}
                         /> */}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="URL"
                      onChange={onChange}
                      id="Userurl"
                      value={Userurl}

                      formControlProps={{
                        fullWidth: true
                      }}
                    />

                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color="primary" type="submit">Add</Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
